export const baseEncrypt = (plainText: string, password: string) => {
  let encryptedText = '';

  if (!plainText) return encryptedText;

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < plainText.length; index++) {
    const charCode = plainText.charCodeAt(index);
    const passwordCharCode = password.charCodeAt(index % password.length);

    // performs a XOR operation between the ASCII code of the character and the password
    // eslint-disable-next-line no-bitwise
    const encryptedCode = charCode ^ passwordCharCode;

    // convert the cipher back to a character and add it to the ciphertext
    encryptedText += String.fromCharCode(encryptedCode);
  }

  return Buffer.from(encryptedText, 'utf-8').toString('base64');
};

export const baseDecrypt = (encryptedText: string, password: string) => {
  let plainText = '';

  if (!encryptedText) return plainText;

  const encryptedTextClean = Buffer.from(encryptedText, 'base64').toString('utf-8');

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < encryptedTextClean.length; index++) {
    const encryptedCode = encryptedTextClean.charCodeAt(index);
    const passwordCharCode = password.charCodeAt(index % password.length);

    // perform reverse XOR operation to obtain the original ASCII code
    // eslint-disable-next-line no-bitwise
    const charCode = encryptedCode ^ passwordCharCode;

    // convert the ASCII code back to a character and add it to the decrypted text
    plainText += String.fromCharCode(charCode);
  }

  return plainText;
};

export enum CurrencyActionTypes {
    select = 'CURRENCY_SELECT',
    setAll = 'CURRENCY_SET_ALL',
  }

export type CurrencyActions =
    | { type: CurrencyActionTypes.select, selected: string }
    | { type: CurrencyActionTypes.setAll, currencies: any[] }

  type Currency = {
    selected: string;
    all: any[];
  }

export type CurrencyState = {
    currency: Currency;
  }

export const currencyReducer = (state: CurrencyState, action: CurrencyActions) => {
  switch (action.type) {
    case CurrencyActionTypes.select:
      return {
        ...state,
        currency: {
          ...state.currency,
          selected: action.selected,
        },
      };
    case CurrencyActionTypes.setAll:
      return {
        ...state,
        currency: {
          ...state.currency,
          all: action.currencies,
        },
      };
    default:
      return state;
  }
};

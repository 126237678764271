import { ProductDocument } from 'types/product.type';

export enum ProductActionTypes {
  select = 'PRODUCT_SELECT',
}

export type ProductActions =
  | { type: ProductActionTypes.select, item: ProductDocument }

export type ProductState = {
  products: {
    selected: ProductDocument | null;
  };
}

export const productReducer = (state: ProductState, action: ProductActions) => {
  switch (action.type) {
    case ProductActionTypes.select:
      return {
        ...state,
        products: {
          ...state.products,
          selected: { ...action.item },
        },
      };
    default:
      return state;
  }
};

import { AlternateLanguage, Document } from '@prismicio/client/types/documents';
import xss from 'xss';
import { HOST } from './constants';

interface Locale {
  key: string;
  title: string;
  path: string;
  variants: string[];
  countryCode: string;
  currencyCode: string;
  currencySymbol: string;
}

export const locales: Locale[] = [
  {
    key: 'es',
    title: '',
    path: '/',
    variants: ['es'],
    countryCode: 'ES',
    currencyCode: '',
    currencySymbol: '',
  },
  {
    key: 'es-AR',
    title: 'Argentina',
    path: '/ar/',
    variants: ['ar', 'es-ar', 'es-AR', 'AR'],
    countryCode: 'AR',
    currencyCode: 'ARS',
    currencySymbol: '$',
  },
  {
    key: 'es-MX',
    title: 'México',
    path: '/mx/',
    variants: ['mx', 'es-mx', 'es-MX', 'MX'],
    countryCode: 'MX',
    currencyCode: 'MXN',
    currencySymbol: '$',
  },
  {
    key: 'es-CO',
    title: 'Colombia',
    path: '/co/',
    variants: ['co', 'es-co', 'es-CO', 'CO'],
    countryCode: 'CO',
    currencyCode: 'COP',
    currencySymbol: '$',
  },
  {
    key: 'pt-BR',
    title: 'Brasil',
    path: '/br/',
    variants: ['pt', 'pt-br', 'pt-BR', 'br', 'BR'],
    countryCode: 'BR',
    currencyCode: 'BRL',
    currencySymbol: 'R$',
  },
  {
    key: 'es-CL',
    title: 'Chile',
    path: '/cl/',
    variants: ['cl', 'es-cl', 'es-CL', 'CL'],
    countryCode: 'CL',
    currencyCode: 'CLP',
    currencySymbol: '$',
  },
  {
    key: 'es-UY',
    title: 'Uruguay',
    path: '/',
    variants: ['es-UY', 'UY'],
    countryCode: 'UY',
    currencyCode: 'UYU',
    currencySymbol: '$',
  },
  {
    key: 'es-PE',
    title: 'Perú',
    path: '/',
    variants: ['es-PE', 'PE'],
    countryCode: 'PE',
    currencyCode: 'USD',
    currencySymbol: 'US$',
  },
];

export const defaultLocale = {
  key: 'es',
  country: 'es-IN',
  countryCode: 'IN',
  currencyCode: 'USD',
  currencySymbol: 'US$',
};

export const isAvailableLocale = (lang: string) => !!locales.find(({ variants }) => variants.includes(lang));

export const getLocale = (lang: string): Locale => {
  const currentLocale = locales.find(({ variants }) => variants.includes(lang));
  return currentLocale ?? locales[0];
};

export const getRealLocale = (lang: string): string => {
  const currentLocale = locales.find(({ variants }) => variants.includes(lang));
  return currentLocale?.key || 'es';
};

export const getCountry = (lang: string): string => {
  const currentLocale = locales.find(({ variants }) => variants.includes(lang));
  return currentLocale?.countryCode || defaultLocale.country;
};

export const getCurrencyCode = (lang: string): string => {
  const currentLocale = locales.find(({ variants }) => variants.includes(lang));
  return currentLocale?.currencyCode || defaultLocale.currencyCode;
};

export const getCurrencySymbol = (lang: string): string => {
  const currentLocale = locales.find(({ variants }) => variants.includes(lang));
  return currentLocale?.currencySymbol || defaultLocale.currencySymbol;
};

export const localePathResolver = (lang) => {
  const currentLocale = locales.find(({ variants }) => variants.includes(lang));
  return currentLocale?.path || '/';
};

export const getBusinessUnitName = (
  key: string,
  lang = 'es',
): string => {
  const businessUnitsLang = {
    es: {
      course: 'curso',
      courses: 'cursos',
      career: 'carrera',
    },
    'pt-BR': {
      course: 'curso',
      courses: 'cursos',
      career: 'carreira',
    },
  };

  const currentLocale = locales.find(({ variants }) => variants.includes(lang))?.key || '';
  const businessUnits = businessUnitsLang[currentLocale] || businessUnitsLang.es;

  return businessUnits[key] || '';
};

export const getUidPath = (
  key: string,
  lang = 'es',
): string => {
  const langPaths = {
    es: {
      products: 'productos',
      courses: 'cursos',
      offer: 'oferta',
    },
    'pt-BR': {
      products: 'produtos',
      courses: 'cursos',
      offer: 'oferta',
    },
  };

  const currentLocale = locales.find(({ variants }) => variants.includes(lang))?.key || '';

  const paths = langPaths[currentLocale] || langPaths.es;

  return paths[key] || '';
};

interface LinkData {
  verticalId?: string,
  clusterId?: string,
}

export const prismicLinkResolver = (doc: Partial<Document>, data?: LinkData, absolute?: boolean): string => {
  const host = absolute ? `https://${HOST}` : '';
  const prefix = `${host}${localePathResolver(doc.lang)}`;
  const verticalId = data?.verticalId || doc?.data?.verticalId || doc?.data?.verticalPage?.uid;

  switch (doc.type) {
    case 'blog':
      return `${prefix}blog/${doc.uid}`;
    case 'page':
      return `${prefix}${doc.uid}`;
    case 'clusterpage':
    {
      const uid = getUidPath('products', doc.lang);
      return verticalId ? `${prefix}${uid}/${verticalId}/${doc.uid}` : prefix;
    }
    case 'products':
    {
      const uid = getUidPath('products', doc.lang);
      const cluster = doc.data?.clusterPage?.uid || data?.clusterId;
      return cluster && verticalId ? `${prefix}${uid}/${verticalId}/${cluster}/${doc.uid}` : prefix;
    }
    case 'verticalpage':
    {
      const uid = getUidPath('products', doc.lang);
      return doc.uid ? `${prefix}${uid}/${doc.uid}` : prefix;
    }
    default:
      return prefix;
  }
};

export const addExtraZeros = (
  number: number,
  minimumIntegerDigits = 2,
): string => number.toLocaleString('en-US', {
  minimumIntegerDigits,
  useGrouping: false,
});

export const slugify = (str: string) => {
  let slug = str.replace(/^\s+|\s+$/g, '').toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  return slug
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
};

export const capitalize = ([first, ...rest]: string): string => first.toUpperCase() + rest.join('').toLowerCase();

export const sanitize = (source?: string | null): string => xss(source || '');

interface getVerticalByLinkReturn {
  uid: string,
  color: string,
  name?: string,
  clusterId?: string
}

export const getVerticalByLink = (link, verticals, clusters?, products?): getVerticalByLinkReturn => {
  const filter = (item) => item.uid === link.uid;
  const getVertical = (cluster) => {
    const verticalId = cluster?.data?.verticalPage?.uid;
    const vertical = verticals?.find((item) => item.uid === verticalId);
    const color = vertical?.data?.color;
    const uid = vertical?.uid;

    return {
      color, uid,
    };
  };

  switch (link?.type) {
    case 'verticalpage': {
      const vertical = verticals?.find(filter);
      const data = vertical?.data ?? {};
      const uid = vertical?.uid;

      return { ...data, uid };
    }
    case 'clusterpage': {
      const cluster = clusters?.find(filter);
      const vertical = getVertical(cluster);

      return {
        ...vertical,
        clusterId: cluster?.uid,
      };
    }
    case 'products': {
      const product = products?.find(filter);
      const cluster = clusters?.find((item) => item.uid === product.data?.clusterPage?.uid);
      const vertical = getVertical(cluster);

      return {
        ...vertical,
        clusterId: cluster.uid,
      };
    }
    default:
      return { uid: '', color: '' };
  }
};

export const getVerticalId = (clusterId: string, clusters: Document[] = []): string => clusters.find(
  (cluster: Document) => cluster.uid === clusterId,
)?.data?.verticalPage?.uid || '';

type scrollToProps = {
  id: string,
  behavior?: ScrollBehavior,
}

export const getElementByKey = (key: string): HTMLElement | null => document.getElementById(key)
  ?? document
    .evaluate(`//section[contains(., '${key}')]`, document, null, XPathResult.ANY_TYPE, null)
    .iterateNext() as HTMLElement;

export const scrollTo = ({ id, behavior = 'smooth' }: scrollToProps) => {
  const element = getElementByKey(id);

  element?.scrollIntoView({ behavior });

  // scroll hack for lazy loading components
  setTimeout(() => element?.scrollIntoView(), 500);
};

export const transformProductCheckoutToPrismic = (data, currencySymbol, locale) => {
  const plan = data.defaultOffer?.plans[0];
  const hasBookingPriceDiscount = plan?.enrollmentAmount !== plan?.remarkedEnrollmentAmount;
  const fixedInstallments = plan?.remarkedInstallmentAmount ?? plan?.installmentAmount;

  const formatNumber = (number: number): string => new Intl
    .NumberFormat(locale)
    .format(number);

  const formatDate = (date: string): string => date?.split('T')[0];

  return {
    type: 'products',
    data: {
      title: data.label ?? null,
      startDate: formatDate(data.startsAt) ?? null,
      endDate: formatDate(data.endsAt) ?? null,
      totalPrice: plan?.programAmount ? `${currencySymbol} ${formatNumber(plan.programAmount)}` : null,
      totalPriceDiscount: null,
      bookingPrice: plan?.enrollmentAmount ? `${currencySymbol} ${formatNumber(plan.enrollmentAmount)}` : null,
      bookingPriceDiscount: hasBookingPriceDiscount
        ? `${currencySymbol} ${formatNumber(plan.remarkedEnrollmentAmount)}` : null,
      fixedInstallmentsLabel: fixedInstallments
        ? `${plan.installmentCount} x ${currencySymbol} ${formatNumber(fixedInstallments)}` : null,
    },
  };
};

export const getDefaultText = (
  key: string,
  primaryMessages: {[key: string]: string},
  defaultMessages: {[key: string]: string},
): string => primaryMessages[key] || (defaultMessages[key] ?? '');

export const getMetaText = (text: string, lang = 'es') => {
  const defaultTitle = process.env.NEXT_PUBLIC_DEFAULT_PAGE_TITLE;
  const { title: countryTitle } = getLocale(lang);
  const suffixTitle = (`${defaultTitle} ${countryTitle}`).trim();

  return text ? `${text} | ${suffixTitle}` : suffixTitle;
};

interface IMetaAlternatesData { pages: (AlternateLanguage | Document)[], clusters?: Document[], absolutePath?: boolean }
interface IMetaAlternateItem { lang: string; href: string; }

export const getMetaAlternates: (params: IMetaAlternatesData) => IMetaAlternateItem[] = ({
  pages,
  clusters = [],
  absolutePath = true,
}) => {
  const metaAlternate: { lang: string, href: string }[] = [];
  const xDefaultPage = pages.find((p) => p.lang === 'es');
  let data;

  pages.forEach((item) => {
    if (isAvailableLocale(item.lang as string)) {
      if (item.type === 'clusterpage') {
        const cluster = clusters.find(({ id }) => id === item.id) ?? {};
        data = { verticalId: (cluster as Document)?.data?.verticalPage?.uid };
      }
      metaAlternate.push({
        lang: getRealLocale(item.lang as string) === 'es' ? 'es-ES' : getRealLocale(item.lang as string),
        href: prismicLinkResolver(item, data, absolutePath),
      });
    }
  });

  if (xDefaultPage) {
    metaAlternate.push({
      lang: 'x-default',
      href: prismicLinkResolver(xDefaultPage, undefined, absolutePath),
    });
  }

  return metaAlternate;
};

export const buildWhatsappLink = (number: number, message?: string) => {
  const WHATSAPP_BASE_URL = 'https://wa.me';
  const text = message ? `text=${encodeURI(message)}` : '';
  const url = `${WHATSAPP_BASE_URL}/${number}?${text}`;

  return url;
};

export const delay = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

export const getDomain = () => {
  let domain = '';

  if (typeof window !== 'undefined') {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    if (parts.length > 2) {
      domain = `.${parts.slice(-2).join('.')}`;
    } else {
      domain = `.${hostname}`;
    }
  }

  return domain;
};

export enum GeoCountryActionTypes {
  add = 'GEO_COUNTRY_ADD',
}

export type GeoCountryActions =
  | { type: GeoCountryActionTypes.add, loc: string }

type GeoCountry = {
  loc: string;
}

export type GeoCountryState = {
  geoCountry: GeoCountry;
}

export const geoCountryReducer = (state: GeoCountryState, action: GeoCountryActions) => {
  switch (action.type) {
    case GeoCountryActionTypes.add:
      return {
        ...state,
        geoCountry: {
          ...state.geoCountry,
          loc: action.loc,
        },
      };
    default:
      return state;
  }
};

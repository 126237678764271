import { createContext, ReactNode, useMemo } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const AnalyticsContext = createContext<AnalyticsBrowser | null>(null);

type Props = {
  writeKey: string;
  children: ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = useMemo(
    () => ((typeof window !== 'undefined') ? AnalyticsBrowser.load({ writeKey }) : null),
    [writeKey],
  );
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

import {
  createContext,
  useMemo,
  Dispatch,
  FC,
  useReducer,
  useEffect,
} from 'react';
import { getPref } from '../utils/prefs';
import { findGeoCountry } from '../utils/analytics';
import {
  BenefitActions, benefitReducer, BenefitState,
} from './reducers/benefit.reducer';
import { ProductActions, productReducer, ProductState } from './reducers/product.reducer';
import {
  GeoCountryActions, GeoCountryActionTypes, geoCountryReducer, GeoCountryState,
} from './reducers/geocountry.reducer';
import {
  CurrencyActions, CurrencyActionTypes, currencyReducer, CurrencyState,
} from './reducers/curency.reducer';

type InitialStateType = BenefitState & ProductState & GeoCountryState & CurrencyState;
type MainActionType = BenefitActions | ProductActions | GeoCountryActions | CurrencyActions;

const locDefault = process.env.NODE_ENV === 'test' ? 'AR' : '';

const initialState = {
  benefits: {
    items: [],
    selected: {},
  },
  products: {
    selected: null,
  },
  geoCountry: {
    loc: locDefault,
  },
  currency: {
    selected: '',
    all: [],
  },
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<MainActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({
  benefits, products, geoCountry, currency,
}: InitialStateType, action: MainActionType) => ({
  ...benefitReducer({ benefits }, action as BenefitActions),
  ...productReducer({ products }, action as ProductActions),
  ...geoCountryReducer({ geoCountry }, action as GeoCountryActions),
  ...currencyReducer({ currency }, action as CurrencyActions),
});

const AppProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  useEffect(() => {
    if (locDefault) return;

    findGeoCountry().then((loc) => {
      dispatch({ type: GeoCountryActionTypes.add, loc });
    });
  }, [dispatch]);

  useEffect(() => {
    const currencyDefault = getPref();
    dispatch({ type: CurrencyActionTypes.select, selected: currencyDefault });
  }, [dispatch]);

  const values = useMemo(() => ({
    state,
    dispatch,
  }), [state]);

  AppContext.displayName = 'Context Display Name';

  return (
    <AppContext.Provider value={values}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };

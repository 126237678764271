import { getCookie, setCookie } from 'cookies-next';
import { baseDecrypt, baseEncrypt } from './crypto';

const PREFS_PASSWORD = process.env.NEXT_PUBLIC_PREFS_PASSWORD as string;
const COOKIE_CURRENCY = 'pref-currency';
const COOKIE_OPTIONS = {
  domain: '.digitalhouse.com',
  maxAge: 60 * 60 * 24 * 365,
};

export const setPref = (
  value: string,
  schemeKey: string = COOKIE_CURRENCY,
  cookieOptions: any = COOKIE_OPTIONS,
) => {
  setCookie(schemeKey, baseEncrypt(value, PREFS_PASSWORD), cookieOptions);
};

export const getPref = (
  schemeKey: string = COOKIE_CURRENCY,
  cookieOptions: any = COOKIE_OPTIONS,
) => {
  const cookieValue = getCookie(schemeKey, cookieOptions) as string;
  const prefSchemeCookie = baseDecrypt(cookieValue, PREFS_PASSWORD);

  return prefSchemeCookie;
};

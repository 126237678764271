import dynamic from 'next/dynamic';
import '../styles/globals.css';
import {
  useMemo, useEffect,
} from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import es from 'lang/es.json';
import esAR from 'lang/es-AR.json';
import esCL from 'lang/es-CL.json';
import ptBR from 'lang/pt-BR.json';
import Script from 'next/script';
import { TABOOLA_ADS_KEY } from 'utils/constants';
import { AnalyticsProvider } from 'contexts/AnalyticsContext';
import { AppProvider } from '../contexts/AppContext';
import { getRealLocale } from '../utils/common';
import { setUtm, mustStoreUtm, getAnalyticsLocaleKey } from '../utils/analytics';
import global from '../utils/global';

const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'es';
const prismicRepo = process.env.NEXT_PUBLIC_PRISMIC_REPO;

const Refiner = dynamic(() => import('../components/RefinerWrapper'), { ssr: false });

// eslint-disable-next-line max-len
const taboolaScript = (key) => `window._tfa=window._tfa||[];window._tfa.push({notify:'event',name:'page_view',id:${key}});!function(t,f,a,x){if(!document.getElementById(x)){t.async=1;t.src=a;t.id=x;f.parentNode.insertBefore(t,f)}}(document.createElement('script'),document.getElementsByTagName('script')[0],'//cdn.taboola.com/libtrc/unip/${key}/tfa.js','tb_tfa_script');`;

if (process.env.NEXT_PUBLIC_E2E && !global.isMockedService) {
  import('../mocks');
  global.isMockedService = true;
}

function MyApp({ Component, pageProps }: AppProps) {
  const { locale, query } = useRouter();
  const currentLocale = getRealLocale(locale || defaultLocale);
  const taboolaKey = TABOOLA_ADS_KEY[currentLocale];

  const messages = useMemo(() => {
    switch (currentLocale) {
      case 'br':
      case 'pt':
      case 'pt-BR':
        return ptBR;
      case 'ar':
      case 'es-AR':
        return esAR;
      case 'cl':
      case 'es-CL':
        return esCL;
      case 'es':
        return es;
      default:
        return es;
    }
  }, [currentLocale]);

  useEffect(() => {
    if (Object.keys(query).length && mustStoreUtm(query)) {
      setUtm({
        ...query,
        url: global?.location?.href || 'Unknown',
      });
    }

    if (document) document.documentElement.lang = currentLocale;
  }, [query, currentLocale]);

  return (
    <AnalyticsProvider writeKey={getAnalyticsLocaleKey(currentLocale)}>
      <AppProvider>
        <IntlProvider locale={currentLocale} messages={messages}>
          <Head>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          </Head>
          <Script
            strategy="afterInteractive"
            src={`https://static.cdn.prismic.io/prismic.js?new=true&repo=${prismicRepo}`}
          />
          {process.env.NEXT_PUBLIC_WIBSON_SRC && (
            <Script
              strategy="afterInteractive"
              src={process.env.NEXT_PUBLIC_WIBSON_SRC}
            />
          )}
          {!!taboolaKey && (
            <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{ __html: taboolaScript(taboolaKey) }}
            />
          )}
          <Refiner />
          <Component {...pageProps} />
        </IntlProvider>
      </AppProvider>
    </AnalyticsProvider>
  );
}
export default MyApp;

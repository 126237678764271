export enum BenefitActionTypes {
  add = 'BENEFIT_ADD',
  select = 'BENEFIT_SELECT',
}

export type BenefitActions =
  | { type: BenefitActionTypes.add, items: object[] }
  | { type: BenefitActionTypes.select, item: object | null }

type Benefit = {
  name?: string;
  title?: string;
  subtitle?: string;
}

export type BenefitState = {
  benefits: {
    items: Benefit[];
    selected: Benefit;
  };
}

export const benefitReducer = (state: BenefitState, action: BenefitActions) => {
  switch (action.type) {
    case BenefitActionTypes.add:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          items: [
            ...state.benefits.items,
            ...action.items,
          ],
        },
      };
    case BenefitActionTypes.select:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          selected: { ...action.item },
        },
      };
    default:
      return state;
  }
};
